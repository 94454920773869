
/*
PLEASE DON'T CANCEL THIS COMMENT, IT'S USEFUL FOR FUTURE MAINTENANCE
CMS MODULE INFORMATIONS:
M – 27.1 – Trends Anchor Buttons (visible on LP)
M - 27.2 - Trend Anchor Buttons (hidden on LP)
M – 26.1 – Anchor Buttons (Visible on LP)
M - 26.2 - ​Anchor Buttons (Hidden on LP)

FIGMA
https://www.figma.com/design/neFYqO4lZLEdfFJZxzobwW/SGH---New-CMS-Editorial-Modules?node-id=1-6780&t=u6zLQiXM7aPNMbKg-0

DOC
https://luxotticaretail.atlassian.net/wiki/spaces/SPM/pages/2466611201/EDITORIAL+MODULE+2+-+New+layout+variants+to+manage+anchor+links
*/
import BaseLayout from './components/trends-anchor-buttons-base-layout.vue'
import ModuleHeader from './components/trends-anchor-buttons-header.vue'
import ModuleAnchorsContainer from './components/trends-anchor-buttons-anchors-container.vue'

export default {
  name: 'SghCmsTrendsAnchorButtons',

  components: {
    BaseLayout,
    ModuleAnchorsContainer,
    ModuleHeader,
  },

  props: {
    backgroundColor: {
      type: String,
      default: '',
    },

    isLandingHidden: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isScrolled: false,
      previousScrollPosition: 0,
      isScrollingDown: true,
      baseClass: 'sgh-cms-trends-anchor-buttons',
      visible: false,
    }
  },

  computed: {
    isMobile() {
      return this.$mq === 'tablet' || this.$mq === 'tabletLandscape'
    },
    isPretitleAvailable() {
      return !!this.$slots.pretitle
    },
    isTitleAvailable() {
      return !!this.$slots.maintitle
    },
    shouldHideTitle() {
      return this.isMobile && this.isScrolled && (this.isPretitleAvailable || this.isTitleAvailable)
    },

    calculateHeaderHeights() {
      const mainMenu = document.getElementsByClassName('sgh-main-menu')[0]
      const benefitBar = document.getElementsByClassName('benefit-bar')[0]
      const headerTop = document.getElementsByClassName('sgh-header-top')[0]
      
      const mainMenuHeight = mainMenu ? mainMenu.offsetHeight : 0
      const benefitBarHeight = benefitBar ? benefitBar.offsetHeight : 0
      const headerTopHeight = headerTop ? headerTop.offsetHeight : 0

      return { mainMenuHeight, benefitBarHeight, headerTopHeight }
    },

    modifierClasses() {
      return {
        [`${this.baseClass}--visible`]: this.visible,
        [`${this.baseClass}--hidden`]: !this.visible,
      }
    },
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    if (this.isLandingHidden === false) {
      this.visible = true
    }
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    handleScroll() {
      const currentScrollPosition = window.scrollY

      if (currentScrollPosition > this.previousScrollPosition) {
        this.isScrollingDown = true
        this.visible = true
      } else {
        this.isScrollingDown = false
      }

      this.previousScrollPosition = currentScrollPosition

      const { mainMenuHeight, benefitBarHeight, headerTopHeight } = this.calculateHeaderHeights
      const body = document.querySelector('body')
      const isPlp = body.classList.contains('bodyPlp')
      const headerNode = document.querySelector('.sgh-header')

      const resizeObserver = new ResizeObserver(() => {
        this.updateTrendsAnchorPosition(headerNode)
      })
      resizeObserver.observe(headerNode)

      if (this.isScrollingDown) {
        if (isPlp) {
            this.$refs.TrendsAnchorButtonsComponent.$el.style.top = `calc(${mainMenuHeight}px + ${benefitBarHeight}px)`
          }
      } else {
        this.$refs.TrendsAnchorButtonsComponent.$el.style.top = `calc(${mainMenuHeight}px + ${benefitBarHeight}px + ${headerTopHeight}px)`
      }

      if (currentScrollPosition > 0) {
        this.isScrolled = true
      } else {
        this.isScrolled = false
      }
    },
    updateTrendsAnchorPosition(newClientHeight){
      this.$refs.TrendsAnchorButtonsComponent.$el.style.top = `${newClientHeight.clientHeight}px`
    },
  },
}
