
import { UPDATE_LOGON_MYACCOUNT } from '../../../scripts/store/mutations/my-account'
import { ACTION_UPDATE_SEARCH_OVERLAY_OPEN } from '../../../scripts/store/actions/search'
import { createNamespacedHelpers } from 'vuex'
import { loadSectionTranslations } from '../../i18n-setup'
import { getCurrentCountry } from '../../libs/utils/currentCountry'
import { getUserType } from '../../libs/wcs/my-account'
import sghNavigationRightPart from './navigation-right-part.vue'

const { mapMutations } = createNamespacedHelpers('myAccount')
const { mapGetters: mapGettersWishlist } = createNamespacedHelpers('wishlist')
const { mapGetters: mapGettersMyAccount } = createNamespacedHelpers('myAccount')
const { mapActions: mapActionsSearch } = createNamespacedHelpers('search')

export default {
  name: 'sgh-header-navigation',
  components: {
    sghNavigationRightPart,
  },
  props: {
    carturl: {
      default: '',
      type: String,
    },
    searchEnabled: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      menuMobileOpen: false,
      scrollY: 0,
      subMenuOpen: false,
      innerHeight: window.innerHeight,
      getHeightMenu: 0,
    }
  },
  created() {
    if (!this.$router)
      getUserType().then(userType => {
        this.updateLoginStatus(userType === 'R')
      })
  },
  mounted() {
    loadSectionTranslations('my-account-new')
    loadSectionTranslations('cms')
    loadSectionTranslations('plp')
    window.addEventListener('scroll', event => {
      this.scrollY = scrollY
      this.getHeightMenu = this.$refs.sghmenu.clientHeight
    })
    window.addEventListener('resize', () => (this.innerHeight = window.innerHeight))
    this.$root.$on('submenuOpen', value => (this.subMenuOpen = value))
    this.$root.$on('closeMenu', () => (this.menuMobileOpen = false))
  },
  computed: {
    ...mapGettersWishlist(['counterWishlist']),
    ...mapGettersMyAccount(['profileDetails']),
    isMobile() {
      return this.$mq == 'tablet' || this.$mq == 'tabletLandscape'
    },
    getWishlistUrl() {
      return `/${getCurrentCountry()}/wishlist`
    },
    currentCountry() {
      return getCurrentCountry()
    },
    goToHome() {
      return `/${getCurrentCountry()}`
    },
    getTopMain() {
      const menu = this.$refs.sghmenu ? this.$refs.sghmenu : null
      return menu ? menu.getAttribute('top') : 0
    },
    menuStyle() {
      return {
        '--vh': `${this.innerHeight * 0.001}rem`,
      }
    },
    quantityWishlist() {
      return this.counterWishlist
    },
    registrationApprovalStatusBoolean() {
      return (
        this.profileDetails?.objectPerson?.personalInfo?.registrationApprovalStatus === 'Approved'
      )
    },
    isLoyaltyEnabled() {
      return sessionStorage.getItem('isLoyaltyProgramEnabled') === 'true'
    },
  },
  methods: {
    ...mapMutations({
      updateLoginStatus: UPDATE_LOGON_MYACCOUNT,
    }),
    ...mapActionsSearch({
      updateOverlayIsOpen: ACTION_UPDATE_SEARCH_OVERLAY_OPEN,
    }),
    toggleMenu() {
      this.innerHeight = window.innerHeight
      this.$refs.menu.style.setProperty('--vh', `${this.innerHeight * 0.001}rem`)
      this.menuMobileOpen = !this.menuMobileOpen
      if (document.body.classList.contains('menu-opened')) {
        document.body.style.removeProperty('overflow')
        document.body.classList.remove('menu-opened')
      } else {
        document.body.style.overflow = 'hidden'
        document.body.classList.add('menu-opened')
      }
    },
    toggleFakeInput(flag) {
      if (flag) {
        document.body.classList.add('fake-input-active')
        if (document.body.classList.contains('fake-input-active')) {
          setTimeout(() => {
            this.updateOverlayIsOpen(true)
          }, 1000)
        }
      } else {
        document.body.classList.remove('fake-input-active')
      }
    },
  },
}
