
export default {
  name: 'sgh-button',
  props: {
    category: {
      type: String,
      default: 'primary',
      validator(value) {
        return ['primary', 'secondary', 'primary-2023', 'ghost-2023', 'tertiary-2023'].includes(
          value
        )
      },
    },
    css: {
      type: Object,
      default: () => ({ 'min-width': '11.4rem' }),
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    href: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    iconPosition: {
      type: String,
      default: 'left',
    },
    isAnchor: {
      type: Boolean,
      default: false,
    },
    LinkText: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    handleClick() {
      if (!this.isAnchor && this.$listeners.click) {
        event.preventDefault()
        this.$emit('click')
      }
    },
  },
}
