
export default {
  name: 'menu-item-mobile',
  data() {
    return {
      submenuOpen: false,
    }
  },
  props: {
    dataElemenId: {
      type: String,
      default: 'MainNav',
    },
    dataMasterId: {
      type: String,
      default: 'Parent',
    },
    menuElementUrl: {
      type: String,
      default: '#',
    },
  },
  computed: {
    hasSubmenu() {
      return !!this.$slots.submenu
    },
    submenuName() {
      return this.$slots.item[0].children[0].text
    },
  },
  methods: {
    getElementUrl() {
      return this.hasSubmenu ? '#' : this.menuElementUrl
    },
    toggleSubmenu() {
      if (this.hasSubmenu) {
        this.submenuOpen = !this.submenuOpen
        this.$root.$emit('submenuOpen', this.submenuOpen)
      }
    },
  },
}
