export const ACTION_LOGON_MYACCOUNT = 'ACTION_LOGON_MYACCOUNT'
export const ACTION_LOGON_RESET_ERROR = 'ACTION_LOGON_RESET_ERROR'
export const ACTION_PROFILE_DETAILS = 'ACTION_PROFILE_DETAILS'
export const ACTION_LOAD_PASSWORD_REQUIREMENTS = 'ACTION_LOAD_PASSWORD_REQUIREMENTS'
export const ACTION_UPDATE_PROFILE = 'ACTION_UPDATE_PROFILE'
export const ACTION_PROFILE_RESET_ERROR = 'ACTION_PROFILE_RESET_ERROR'
export const ACTION_LOGOUT_MYACCOUNT = 'ACTION_LOGOUT_MYACCOUNT'
export const ACTION_RESET_URL = 'ACTION_RESET_URL'
export const ACTION_CHANGE_PASSWORD = 'ACTION_CHANGE_PASSWORD'
export const ACTION_PROFILE_DETAILS_ADDRESS = 'ACTION_PROFILE_DETAILS_ADDRESS'
export const ACTION_UPDATE_USER_ADDRESS = 'ACTION_UPDATE_USER_ADDRESS'
export const ACTION_DELETE_PROFILE_DETAILS_ADDRESS = 'ACTION_DELETE_PROFILE_DETAILS_ADDRESS'
export const ACTION_UPDATE_SHIPPING_AND_BILLING = 'ACTION_UPDATE_SHIPPING_AND_BILLING'
export const ACTION_SINGLE_ADDRESS = 'ACTION_SINGLE_ADDRESS'
export const ACTION_LOAD_ORDER_RETURN_HISTORY = 'ACTION_LOAD_ORDER_RETURN_HISTORY'
export const ACTION_ORDER_DETAIL_UPLOAD_PRESCRIPTION = 'ACTION_ORDER_DETAIL_UPLOAD_PRESCRIPTION'
export const ACTION_UPDATE_BREADCRUMB = 'ACTION_UPDATE_BREADCRUMB'
export const ACTION_ADDRESS_GET_MX_ZIPECODE_AUTOCOMPLETE =
  'ACTION_ADDRESS_GET_MX_ZIPECODE_AUTOCOMPLETE'
export const ACTION_GET_STATE_AND_ZIP_REGEX = 'ACTION_GET_STATE_AND_ZIP_REGEX'
export const ACTION_GET_ORDER_LABEL = 'ACTION_GET_ORDER_LABEL'
export const ACTION_GET_DOWNLOAD_INVOICE_FILE = 'ACTION_GET_DOWNLOAD_INVOICE_FILE'
export const ACTION_LOAD_FAVOURITE_STORES = 'ACTION_LOAD_FAVOURITE_STORES'
export const ACTION_LOAD_APPOINTMENTS_BOOKING = 'ACTION_LOAD_APPOINTMENTS_BOOKING'
export const ACTION_LOAD_LOYALTY_PRODUCTS = 'ACTION_LOAD_LOYALTY_PRODUCTS'
export const ACTION_SELECT_LOYALTY_PRODUCT = 'ACTION_SELECT_LOYALTY_PRODUCT'
export const ACTION_LOAD_LOYALTY_SCRIPT = 'ACTION_LOAD_LOYALTY_SCRIPT'
export const ACTION_LOYALTY_STATUS = 'ACTION_LOYALTY_STATUS'
export const ACTION_JOIN_LOYALTY = 'ACTION_JOIN_LOYALTY'
export const ACTION_UPDATE_CART_STATE = 'ACTION_UPDATE_CART_STATE'
export const ACTION_REFRESH_CART_STATE = 'ACTION_REFRESH_CART_STATE'
export const ACTION_RESET_CART_STATE = 'ACTION_RESET_CART_STATE'

import VueCookies from 'vue-cookies'
import isEmpty from 'lodash/isEmpty'
import md5 from 'md5'
import {
  UPDATE_LOGON_MYACCOUNT,
  UPDATE_ERROR_LOGON_MYACCOUNT,
  UPDATE_LOGON_RESET_ERROR,
  UPDATE_ERROR_PROFILE_DETAILS,
  UPDATE_PROFILE_DETAILS,
  UPDATE_RESET_ERROR_PROFILE_DETAILS,
  UPDATE_PASSWORD_CHANGED,
  UPDATE_ADDRESS,
  UPDATE_SINGLE_ADDRESS,
  UPDATE_PROFILE_API_STATUS,
  UPDATE_CRYPTO_DATA_MYACCOUNT,
  UPDATE_CHANGE_PASSWORD_ERRROR,
  UPDATE_ORDER_RETURN_HISTORY,
  UPDATE_ERROR_MESSAGE_HISTORY,
  UPDATE_ORDER_DETAIL_UPLOAD_PRESCRIPTION,
  UPDATE_ERROR_MESSAGE_ORDER_DETAIL_UPLOAD_PRESCRIPTION,
  UPDATE_BREADCRUMB,
  UPDATE_ADDRESS_ZIPCODE_AUTOCOMPLETE,
  UPDATE_ADDRESS_EMPTY_ZIPCODE_AUTOCOMPLETE,
  UPDATE_PASSWORD_REQUIREMENTS,
  UPDATE_STATE_AND_ZIP_REGEX,
  UPDATE_URL_ORDER_LABEL,
  UPDATE_ERROR_MESSAGE_RETURN_LABEL,
  UPDATE_LOADER_ORDER_RETURN_HISTORY,
  UPDATE_ERROR_DOWNLOAD_INVOICE_FILE,
  UPDATE_INVOICE_FILE,
  UPDATE_FAVOURITESTORES_LOADING,
  UPDATE_APPOINTMENTSBOOKING_LOADING,
  UPDATE_LOYALTYPRODUCTS_LOADING,
  UPDATE_LOYALTY_SELECTED_PRODUCT,
  UPDATE_LOAD_LOYALTY_SCRIPT,
  UPDATE_LOYALTY_STATUS,
  UPDATE_CART_STATE,
} from '../mutations/my-account'

import {
  userEndpoint,
  profileDetailsEndpoint,
  userUpdateEndpoint,
  logoutEndpoint,
  forgotEndpoint,
  changePasswordEndpoint,
  addUserAddress,
  deleteUserAddress,
  updateShippingAndBilling,
  getAddressEndpoint,
  editUserAddress,
  digestMessage,
  getOrderReturnHistory,
  uploadPrescriptionOrderDetail,
  addressZipcodeAutocomplete,
  getPasswordRequirements,
  getStateAndZipRegex,
  getOrderLabel,
  downloadInvoiceFile,
  getFavouriteStores,
  getAppointmentsBooking,
  getLoyaltyItems,
  getLoyaltyStatus,
  loyaltyJoinUser,
  getCurrentCartStateFromServer,
} from '../../libs/wcs/my-account'

import { getNumberitem } from '../../libs/wcs/minicart'

const getCookieValue = name =>
  document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''

const createCrypto = async (textVal, isPhone) => {
  let crypto = {}
  let SHA256 = isPhone ? 'SHA256Phone' : 'SHA256Email'
  let MD5Str = isPhone ? 'string_md5_Phone' : 'string_md5'
  let MD5 = isPhone ? MD5Str : 'md5'

  if (getCookieValue(MD5) != '' && getCookieValue(SHA256) != '') {
    crypto = {
      string_md5: getCookieValue(MD5Str),
      sha256: getCookieValue(SHA256),
    }
  } else {
    const result256 = await digestMessage(textVal)
    const resultMd5 = md5(textVal.trim().toLowerCase())
    crypto = {
      string_md5: resultMd5,
      sha256: result256,
    }

    VueCookies.set(MD5Str, crypto.string_md5, null, '/')
    VueCookies.set(SHA256, crypto.sha256, null, '/')
  }
  return crypto
}

export const actions = {
  [ACTION_LOGON_MYACCOUNT]: async function({ dispatch, commit }, parameters) {
    const data = await userEndpoint(parameters)

    if (data.error) {
      commit(UPDATE_ERROR_LOGON_MYACCOUNT, data.error)
    } else {
      await getNumberitem()
      const crypto = await createCrypto(parameters.email)
      commit(UPDATE_CRYPTO_DATA_MYACCOUNT, crypto)
      commit(UPDATE_LOGON_MYACCOUNT, true)
    }
  },
  [ACTION_LOGON_RESET_ERROR]: async function({ commit }) {
    commit(UPDATE_LOGON_RESET_ERROR)
  },

  [ACTION_PROFILE_DETAILS_ADDRESS]: async function({ commit }, parameters) {
    const data = await addUserAddress(parameters)
    if (data.error) {
      commit(UPDATE_ERROR_PROFILE_DETAILS, data.error)
    } else {
      const dataRes = await getAddressEndpoint(parameters)
      dataRes.error
        ? commit(UPDATE_ERROR_PROFILE_DETAILS, dataRes.error)
        : commit(UPDATE_ADDRESS, dataRes)
    }
  },

  [ACTION_PROFILE_DETAILS]: async function({ commit, dispatch, state }) {
    //api is called from both menu component and myAccount app, make sure isn't fired multiple times:
    if (state.profileApiBusy == false && isEmpty(state.profileDetails.objectPerson)) {
      commit(UPDATE_PROFILE_API_STATUS, true)
      const data = await profileDetailsEndpoint()
      if (data.error) {
        commit(UPDATE_ERROR_PROFILE_DETAILS, data.error)
      } else {
        if (data !== undefined && data.loginInfo && data.loginInfo[0]) {
          let crypto = await createCrypto(data.loginInfo[0])
          commit(UPDATE_CRYPTO_DATA_MYACCOUNT, crypto)
        }
        if (data !== undefined && data.personalInfo.phoneNumber) {
          await createCrypto(data.personalInfo.phoneNumber, true)
        }
        commit(UPDATE_PROFILE_DETAILS, data)
      }
      commit(UPDATE_PROFILE_API_STATUS, false)
    }
  },

  [ACTION_LOAD_PASSWORD_REQUIREMENTS]: async function({ commit }) {
    const response = await getPasswordRequirements()
    if (response.error) {
      //commit(UPDATE_ERROR_PROFILE_DETAILS, data.error)
    } else {
      commit(UPDATE_PASSWORD_REQUIREMENTS, response.data.validationRules)
    }
  },

  [ACTION_PROFILE_RESET_ERROR]: async function({ commit }) {
    commit(UPDATE_RESET_ERROR_PROFILE_DETAILS)
  },
  [ACTION_UPDATE_PROFILE]: async function({ commit }, parameters) {
    const data = await userUpdateEndpoint(parameters)
    if (data.error) {
      commit(UPDATE_ERROR_PROFILE_DETAILS, data.error)
    } else {
      const dataRes = await profileDetailsEndpoint(parameters)
      dataRes.error
        ? commit(UPDATE_ERROR_PROFILE_DETAILS, dataRes.error)
        : commit(UPDATE_PROFILE_DETAILS, dataRes)
    }
  },
  [ACTION_UPDATE_USER_ADDRESS]: async function({ commit }, parameters) {
    const data = await editUserAddress(parameters)
    if (data.error) {
      commit(UPDATE_ERROR_PROFILE_DETAILS, data.error)
    } else {
      const dataRes = await getAddressEndpoint(parameters)
      dataRes.error
        ? commit(UPDATE_ERROR_PROFILE_DETAILS, dataRes.error)
        : commit(UPDATE_ADDRESS, dataRes)
    }
  },
  [ACTION_UPDATE_SHIPPING_AND_BILLING]: async function({ commit }, parameters) {
    const data = await updateShippingAndBilling(parameters)
    if (data.error) {
      commit(UPDATE_ERROR_PROFILE_DETAILS, data.error)
    } else {
      const dataRes = await getAddressEndpoint(parameters)
      dataRes.error
        ? commit(UPDATE_ERROR_PROFILE_DETAILS, dataRes.error)
        : commit(UPDATE_ADDRESS, dataRes)
    }
  },

  [ACTION_DELETE_PROFILE_DETAILS_ADDRESS]: async function({ commit }, parameters) {
    const data = await deleteUserAddress(parameters)
    if (!data.error) {
      const dataRes = await getAddressEndpoint(parameters)
      dataRes.error
        ? commit(UPDATE_ERROR_PROFILE_DETAILS, dataRes.error)
        : commit(UPDATE_ADDRESS, dataRes)
    }
  },
  [ACTION_LOGOUT_MYACCOUNT]: async function({ dispatch, commit }, value) {
    const data = await logoutEndpoint()
    dispatch(ACTION_RESET_CART_STATE)
    if (!data.error) {
      localStorage.removeItem('SGH_CARTITEMS')
      commit(UPDATE_LOGON_MYACCOUNT, value)
    }
  },

  [ACTION_RESET_URL]: async function({}, dataToSend) {
    const data = await forgotEndpoint(dataToSend)
    return data ? true : false
  },

  [ACTION_CHANGE_PASSWORD]: async function({ commit }, params) {
    const data = await changePasswordEndpoint(params)
    if (!data.error) {
      commit(UPDATE_PASSWORD_CHANGED, true)
    } else {
      data.error.length && data.error[0].errorCode && data.error[0].errorCode === 'CMN0409E'
        ? commit(UPDATE_CHANGE_PASSWORD_ERRROR, true)
        : data.error.length && data.error[0].errorMessage
        ? commit(UPDATE_ERROR_LOGON_MYACCOUNT, data.error[0].errorMessage)
        : null
    }
  },

  [ACTION_SINGLE_ADDRESS]: async function({ commit }, contact) {
    commit(UPDATE_SINGLE_ADDRESS, contact)
  },

  [ACTION_LOAD_ORDER_RETURN_HISTORY]: async function({ commit }, email) {
    commit(UPDATE_LOADER_ORDER_RETURN_HISTORY, true)
    const data = await getOrderReturnHistory(email)
    commit(UPDATE_LOADER_ORDER_RETURN_HISTORY, false)
    if (data !== undefined) {
      commit(UPDATE_ORDER_RETURN_HISTORY, data)
    } else {
      commit(UPDATE_ERROR_MESSAGE_HISTORY, data)
    }
  },

  [ACTION_ORDER_DETAIL_UPLOAD_PRESCRIPTION]: async function({ commit }, params) {
    const response = await uploadPrescriptionOrderDetail(params)
    if (!response.error) {
      commit(UPDATE_ORDER_DETAIL_UPLOAD_PRESCRIPTION, response)
    } else {
      commit(UPDATE_ERROR_MESSAGE_ORDER_DETAIL_UPLOAD_PRESCRIPTION, response.error)
    }
  },

  [ACTION_UPDATE_BREADCRUMB]: async function({ commit }, params) {
    commit(UPDATE_BREADCRUMB, params)
  },
  [ACTION_ADDRESS_GET_MX_ZIPECODE_AUTOCOMPLETE]: async function({ commit }, zipcode) {
    const data = await addressZipcodeAutocomplete(zipcode.replace('-', ''))
    data.colony.length != 0
      ? commit(UPDATE_ADDRESS_ZIPCODE_AUTOCOMPLETE, data)
      : commit(UPDATE_ADDRESS_EMPTY_ZIPCODE_AUTOCOMPLETE, false)
  },

  [ACTION_GET_STATE_AND_ZIP_REGEX]: async function({ commit }) {
    const data = await getStateAndZipRegex()
    if (!data.error) {
      commit(UPDATE_STATE_AND_ZIP_REGEX, data)
    }
  },

  [ACTION_GET_ORDER_LABEL]: async function({ commit }, url) {
    const data = await getOrderLabel(url)
    data.error
      ? commit(UPDATE_ERROR_MESSAGE_RETURN_LABEL, data.error)
      : commit(UPDATE_URL_ORDER_LABEL, data.labelImage)
  },

  [ACTION_GET_DOWNLOAD_INVOICE_FILE]: async function({ commit }, orderId) {
    const data = await downloadInvoiceFile(orderId)
    data.error
      ? commit(UPDATE_ERROR_DOWNLOAD_INVOICE_FILE, data.error)
      : commit(UPDATE_INVOICE_FILE, data)
  },

  [ACTION_LOAD_FAVOURITE_STORES]: async function({ commit }) {
    const data = await getFavouriteStores()
    commit(UPDATE_FAVOURITESTORES_LOADING, data)
  },
  [ACTION_LOAD_APPOINTMENTS_BOOKING]: async function({ commit }) {
    const data = await getAppointmentsBooking()
    commit(UPDATE_APPOINTMENTSBOOKING_LOADING, data)
  },

  [ACTION_LOAD_LOYALTY_PRODUCTS]: async function({ commit }, param) {
    const data = await getLoyaltyItems(param)
    commit(UPDATE_LOYALTYPRODUCTS_LOADING, data)
  },
  [ACTION_SELECT_LOYALTY_PRODUCT]: async function({ commit }, index) {
    commit(UPDATE_LOYALTY_SELECTED_PRODUCT, index)
  },
  [ACTION_LOAD_LOYALTY_SCRIPT]: async function({ commit }, param) {
    commit(UPDATE_LOAD_LOYALTY_SCRIPT, param)
  },
  [ACTION_LOYALTY_STATUS]: async function({ commit }, param) {
    const data = await getLoyaltyStatus(param)
    commit(UPDATE_LOYALTY_STATUS, data)
  },
  [ACTION_JOIN_LOYALTY]: async function({ commit }, { email, loyaltyCommunication }) {
    const data = await loyaltyJoinUser({
      email,
      loyaltyCommunication,
    })
    if (!data.error) {
      commit(UPDATE_LOYALTY_STATUS, data)
    }
    return data
  },
  [ACTION_UPDATE_CART_STATE]: function({ commit }, payload) {
    commit(UPDATE_CART_STATE, payload)
  },
  [ACTION_REFRESH_CART_STATE]: async function({ dispatch }) {
    const data = await getCurrentCartStateFromServer()
    dispatch(ACTION_UPDATE_CART_STATE, data)
  },
  [ACTION_RESET_CART_STATE]: function({ dispatch }) {
    localStorage.setItem('savedCartState', '')
    localStorage.setItem('Updated_Cart_After_Loggin', '')
    dispatch(ACTION_UPDATE_CART_STATE, [])
  },
}
