
export default {
   name: 'SghCmsTrendsAnchorHeader',

  props: {
    bgColor: {
      type: String,
      default: '',
    },
  },
   
  computed: {
    isMobile() {
      return this.$mq == 'tablet' || this.$mq == 'tabletLandscape'
    },
    isPretitleAvailable() {
      return !!this.$slots.pretitle
    },
    isTitleAvailable() {
      return !!this.$slots.maintitle
    },
  },
}
