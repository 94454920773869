import wcs from '../libs/wcs/index'
const { currency } = wcs.getStoreConfig()
const unavailableData = 'unavailable'
const breadcrumbs = 'monetate_breadcrumbs'

export class MonetateHelper {
  #vuexStore = null
  monenateComponentInstance = null
  test = null

  constructor() {
    window.monetateQ = window.monetateQ || []
  }

  initMonetate({ vuexStore, monenateComponentInstance }) {
    this.#vuexStore = vuexStore
    this.monenateComponentInstance = monenateComponentInstance
    this.test = vuexStore
    this.initMonetateBreadcrumbs()
    this.reTrack()
  }

  setPage() {
    const pageName = window.pageType
    window.monetateQ.push(['setPageType', pageName])
  }

  addProducts() {
    let products = null
    const hpCarouselItems = localStorage.getItem('hpCarouselItems')
    if (wcs.isHomePage() && hpCarouselItems) {
      products = JSON.parse(hpCarouselItems)
      localStorage.setItem('hpCarouselItems', '')
    } else {
      products = this.#vuexStore.getters['plp/products']
    }

    let monetateTrackingProducts = []

    if (products && products.length) {
      monetateTrackingProducts = products.map(currentProduct => {
        return currentProduct.catentryId
      })
    } else {
      return undefined
    }

    window.monetateQ.push(['addProducts', monetateTrackingProducts])
  }

  addProductDetails() {
    const selectedProduct = window.productData
    // TODO: This allColors const is no more evaluted after new PDP redesign.
    // Must be checked if it is necessary having this code
    const allColors = wcs.getProductsColorJson()
    let productDetails = {}

    if (!wcs.isPDP()) {
      return undefined
    }

    for (let color in allColors) {
      const colorItem = allColors[color]
      if (colorItem.partNumber === selectedProduct.productId) {
        productDetails = {
          productId: colorItem.uniqueID,
          sku: colorItem.partNumber,
        }
      }
    }

    window.monetateQ.push(['addProductDetails', [productDetails]])
  }

  addCategories() {
    let category = [window.pageType]

    if (wcs.isPLP()) {
      this.checkAndPush(category, window.categoryIdentifier, window.categoryDescriptionName)
    }

    if (wcs.isPDP()) {
      this.checkAndPush(
        category,
        window.productData?.categoryPath[0].Name,
        window.productData?.productName
      )
    }

    window.monetateQ.push(['addCategories', [...category]])
  }

  addBreadcrumbs() {
    let breadcrumbsLabels = localStorage.getItem(breadcrumbs)

    if (breadcrumbsLabels) {
      breadcrumbsLabels = JSON.parse(breadcrumbsLabels)
    } else {
      breadcrumbsLabels = unavailableData
    }

    window.monetateQ.push(['addBreadcrumbs', [...breadcrumbsLabels]])
  }

  addCartRows() {
    const productsInCart = this.#vuexStore.getters['myAccount/cartState']
    let mappedProducts = unavailableData

    if (productsInCart.length) {
      mappedProducts = productsInCart.map(item => {
        return {
          productId: item?.partNumber,
          quantity: String(parseInt(item?.quantity)),
          unitPrice: String(parseFloat(Math.round(item?.listPrice * 100) / 100).toFixed(2)),
          currency,
        }
      })
    } else {
      return undefined
    }

    window.monetateQ.push(['addCartRows', [...mappedProducts]])
  }

  addPurchaseRows() {
    let purchaseRows = unavailableData

    if (wcs.isTYP()) {
      purchaseRows = window.purchaseRows?.map(item => {
        return {
          purchaseId: item.purchaseId,
          productId: item?.productId,
          quantity: String(parseInt(item?.quantity)),
          unitPrice: String(item?.unitPrice),
          currency,
        }
      })
    }

    window.monetateQ.push(['addPurchaseRows', purchaseRows])
  }

  pushTrackData() {
    window.monetateQ.push(['trackData'])
  }

  reTrack() {
    this.setPage()
    this.addProducts()
    this.addProductDetails()
    this.addCategories()
    this.addBreadcrumbs()
    this.addCartRows()
    this.addPurchaseRows()
    this.pushTrackData()
  }

  checkAndPush(arrayItem, ...dataArr) {
    dataArr.forEach(dataItem => {
      if (dataItem) {
        arrayItem.push(dataItem)
      }
    })
  }

  initMonetateBreadcrumbs() {
    let breadcrumbsArray = localStorage.getItem(breadcrumbs)
    if (breadcrumbsArray) {
      breadcrumbsArray = JSON.parse(breadcrumbsArray)
      const lastPageName = breadcrumbsArray[breadcrumbsArray.length - 1]
      if (lastPageName === window.pageType) {
        return
      }
      breadcrumbsArray.push(window.pageType)
    } else {
      breadcrumbsArray = [window.pageType]
    }

    if (breadcrumbsArray.length > 3) {
      breadcrumbsArray.shift()
    }

    localStorage.setItem(breadcrumbs, JSON.stringify(breadcrumbsArray))
  }

  static createMonetateModule() {
    if (window.MonetateHelper) {
      return window.MonetateHelper
    }
    return new MonetateHelper()
  }
}

//create MonetateHelper instance
window.MonetateHelper = MonetateHelper.createMonetateModule()

export default window.MonetateHelper
