
import { loadSectionTranslations } from '../../i18n-setup'
export default {
  name: 'sgh-header-top',
  data() {
    return {
      currentIndex: 0,
      hoverText: false,
    }
  },
  props: {
    bgColor: {
      default: 'cms-custom-color-black',
      type: String,
    },
    textColor: {
      default: 'cms-custom-text-color-mercury-white',
      type: String,
    },
    controller: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    isDesktop() {
      return this.$mq !== 'tablet' && this.$mq !== 'tabletLandscape'
    },
    numberOfText() {
      const text = this.$slots['swipe']?.filter(c => !c.text)
      return text?.length
    },
    colorIcon() {
      if (this.bgColor === 'cms-custom-color-black') {
        return 'white'
      } else {
        return 'black'
      }
    },
  },

  mounted() {
    loadSectionTranslations('cms')
    setInterval(() => {
      if (!this.hoverText) {
        this.next()
      }
    }, 5000)
  },

  methods: {
    heandlerClick(e) {
      if (this.controller) {
        this.$root.$emit('activeController', this.currentIndex)
      } else if (!this.controller && e === 'arrow') {
        this.next()
      }
    },

    handlerClickPrevious(e) {
      if (!this.controller && e === 'arrow') {
        this.previous()
      }
    },

    next() {
      if (this.currentIndex < this.numberOfText - 1) {
        this.currentIndex++
      } else {
        this.currentIndex = 0
      }
    },

    previous() {
      if (this.currentIndex > 0) {
        this.currentIndex--
      } else {
        this.currentIndex = this.numberOfText - 1
      }
    },
  },
}
