
import { createNamespacedHelpers } from 'vuex'
import { loadSectionTranslations } from '../../../../i18n-setup'
import { getCurrentCountry } from '../../../../libs/utils/currentCountry'

const { mapGetters: mapGettersMyAccount } = createNamespacedHelpers('myAccount')

export default {
  name: 'sgh-menu-wrapper-mobile',

  computed: {
    ...mapGettersMyAccount(['myAccountDetails', 'profileDetails']),

    loggedInState() {
      return this.myAccountDetails.loggedIn
    },

    getMyAccountUrl() {
      return `/${getCurrentCountry()}/myaccount/`
    },

    getRegistrationUrl() {
      return `/${getCurrentCountry()}/myaccount/registration`
    },
  },

  mounted() {
    loadSectionTranslations('my-account-new')
  },
}
