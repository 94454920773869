
export default {
  name: 'SghCmsTrendsAnchorButtonsAnchorLayout',

  props: {
    href: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    scrollToRef(event){
      event.preventDefault()
      const anchorId = new URL(this.href).hash.substring(1)
      const element = document.getElementById(anchorId)
      const offset = 200
      const bodyRect = document.body.getBoundingClientRect().top
      const elementRect = element.getBoundingClientRect().top
      const elementPosition = elementRect - bodyRect
      const offsetPosition = elementPosition - offset

      window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
      })
    },
  },
}
