
import MonetateHelper from '../../helpers/monetateHelper'
import wcs from '../../libs/wcs'
import {
  ACTION_UPDATE_CART_STATE,
  ACTION_RESET_CART_STATE,
  ACTION_REFRESH_CART_STATE,
} from '../../store/actions/my-account'
import { mapGetters, createNamespacedHelpers } from 'vuex'
const { mapActions, mapGetters: mapGettersMyAccount } = createNamespacedHelpers('myAccount')
const { mapGetters: mapGettersPlp } = createNamespacedHelpers('plp')
export default {
  name: 'sgh-monetate',
  computed: {
    ...mapGettersPlp(['products', 'facetsSelected']),
    ...mapGettersMyAccount(['cartState']),
  },
  methods: {
    updateCartRows(payload) {
      this.setCartStateToLocalStorage(payload)
      this.updateCartState(payload)
    },
    ...mapActions({
      updateCartState: ACTION_UPDATE_CART_STATE,
      clearUserCartState: ACTION_RESET_CART_STATE,
      refreshCartStateAfterLoggin: ACTION_REFRESH_CART_STATE,
    }),
    setCartStateToLocalStorage(payload) {
      localStorage.setItem('savedCartState', JSON.stringify(payload))
    },
    updateCartStateFromLocalStorage() {
      const savedCartState = localStorage.getItem('savedCartState')

      if (savedCartState) {
        this.updateCartState(JSON.parse(savedCartState))
      }
    },
    trackHomePageCarousel(carouselItems) {
      localStorage.setItem('hpCarouselItems', JSON.stringify(carouselItems))
      MonetateHelper.reTrack()
    },
  },
  watch: {
    products(newVal, oldVal) {
      if (oldVal.length !== newVal.length) {
        MonetateHelper.reTrack()
      }
    },
    cartState: {
      // this deep watching created for tracking changes inside  internal objects
      handler() {
        MonetateHelper.reTrack()
      },
      deep: true,
    },
    facetsSelected() {
      MonetateHelper.reTrack()
    },
  },
  mounted() {
    //temporarry field for monetate inspector bugfix
    window.dojo = window.dojo || {}
    window.dojo.version = '12'

    MonetateHelper.initMonetate({
      vuexStore: this.$store,
      monenateComponentInstance: this,
    })

    if (
      sessionStorage.getItem('User_LoginStatus') === 'Logged' &&
      !localStorage.getItem('Updated_Cart_After_Loggin')
    ) {
      this.refreshCartStateAfterLoggin()
    } else {
      //check saved cartRows
      this.updateCartStateFromLocalStorage()
    }

    //remove UserCartState after payment
    if (wcs.isTYP()) {
      this.clearUserCartState()
    }
  },
}
