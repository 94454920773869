import makeDebug from 'debug'
import remove from 'lodash/remove'
import { getCurrentCountry } from '../../libs/utils/currentCountry'
const debug = makeDebug('store:mutation')

export const UPDATE_LOAD_WISHLIST_PRODUCTS = 'UPDATE_LOAD_WISHLIST_PRODUCTS'
export const UPDATE_WISHLIST_EMAIL_SUBSCRIPTION = 'UPDATE_WISHLIST_EMAIL_SUBSCRIPTION'
export const UPDATE_ERROR_EMAIL_ME = 'UPDATE_ERROR_EMAIL_ME'
export const UPDATE_EMAIL_ME_RESET_ERROR = 'UPDATE_EMAIL_ME_RESET_ERROR'
export const UPDATE_COUNT_WISHLIST_ITEM = 'UPDATE_COUNT_WISHLIST_ITEM'
export const UPDATE_REMOVE_FROM_WISHLIST = 'UPDATE_REMOVE_FROM_WISHLIST'
export const UPDATE_ERROR_ADD_TO_BAG_WISHLIST_CUSTOMIZED =
  'UPDATE_ERROR_ADD_TO_BAG_WISHLIST_CUSTOMIZED'
export const UPDATE_IS_SHARED_WISHLIST = 'UPDATE_IS_SHARED_WISHLIST'
export const UPDATE_WISHLIST_LIGHT_DATA = 'UPDATE_WISHLIST_LIGHT_DATA'

export const mutations = {
  [UPDATE_LOAD_WISHLIST_PRODUCTS]: function(state, payload) {
    debug('%s payload %o', UPDATE_LOAD_WISHLIST_PRODUCTS, payload)
    const { products, sortOptionsList, shareOptionsList, shareInfo } = payload
    state.sortOptionsList = sortOptionsList
    state.shareOptionsList = shareOptionsList
    state.shareInfo = shareInfo
    if (products) state.products = [...products]
  },
  [UPDATE_REMOVE_FROM_WISHLIST]: function(state, catentryId) {
    if (catentryId) {
      let newProds = remove(state.products, function(product) {
        return product.catentryId != catentryId
      })
      state.products = newProds
    }
  },
  [UPDATE_WISHLIST_EMAIL_SUBSCRIPTION]: function(state, payload) {
    state.marketing.emailSubscription = payload.data
  },
  [UPDATE_ERROR_EMAIL_ME]: function(state, payload) {
    state.emailMeError = payload
  },
  [UPDATE_EMAIL_ME_RESET_ERROR]: function(state) {
    state.emailMeError = null
  },
  [UPDATE_COUNT_WISHLIST_ITEM]: function(state, payload) {
    state.counterWishlist = payload
  },
  [UPDATE_ERROR_ADD_TO_BAG_WISHLIST_CUSTOMIZED]: function(state, payload) {
    state.error = payload
  },
  [UPDATE_IS_SHARED_WISHLIST]: function(state, payload) {
    state.shareInfo.isShared = payload
  },
  [UPDATE_WISHLIST_LIGHT_DATA]: function(state, payload) {
    localStorage.setItem(`wishlistItems_${getCurrentCountry()}`, JSON.stringify(payload))
    state.productsCatentryArr = payload
  },
}
