
export default {
  name: 'SghCmsTrendsAnchorButtonsBaseLayout',

  computed: {
    bindSlotsLoading() {
      return this.$slots
    },
    isMobile() {
      return this.$mq == 'tablet' || this.$mq == 'tabletLandscape'
    },
  },
}
