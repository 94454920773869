import makeDebug from 'debug'
const debug = makeDebug('store:action')

import {
  UPDATE_LOAD_SUGGESTIONS,
  UPDATE_LOAD_SUGGESTED_PRODUCTS,
  UPDATE_SEARCH_OVERLAY_OPEN,
  UPDATE_SEARCH_TERM,
  UPDATE_REDIRECT_URL,
  UPDATE_CLEAR_SUGGESTED_PRODUCTS,
  UPDATE_LOAD_MORE_SUGGESTED_PRODUCTS,
  UPDATE_LOAD_MORE_SUGGESTED_CONTENT,
  UPDATE_LOAD_SUGGESTED_CONTENT,
  UPDATE_LOAD_CMS_PLACEMENTS,
  UPDATE_NO_RESULT,
  UPDATE_TOP_BRANDS,
  UPDATE_LOAD_PLP_FACETS,
  UPDATE_FACETS,
  UPDATE_IDS_FILTERS_CHECKED,
  UPDATE_UI,
  UPDATE_UI_BODY,
  UPDATE_REMOVE_FACET_COUNT,
  UPDATE_FILTER_CATEGORY_LABEL,
  UPDATE_CLONE_FILTER_OPTIONS_LIST,
  UPDATE_IS_FACETS_FIRST_LOAD,
  UPDATE_LAST_SEARCH_TERM,
  UPDATE_RETRIEVE_FAVORITE_LIST_FROM_SEARCH,
  UPDATE_RETRIEVE_FAVORITE_LIST_FROM_SEARCH_NO_RESULT,
  UPDATE_TOGGLE_FAVORITE_FROM_SEARCH,
  UPDATE_TOGGLE_FAVORITE_FROM_SEARCH_NO_RESULT,
  UPDATE_FACETS_COUNT,
  UPDATE_RESET_PAGINATION_DATA,
} from '../mutations/search'

import { getSuggestions, getSuggestedContent } from '../../libs/wcs/search-autocomplete'
import {
  algoliaGetTrendingItems,
  algoliaGetRule,
  algoliaGetTrendingFacets,
} from '../../libs/utils/algoliaIntegration'
import {
  getAlgoliaProductNormalized,
  getAlgoliaTopBrandsNormalized,
} from '../../libs/algolia-normalizer'
import { getPlpFacetStart, getPlpFacets } from '../../libs/wcs/plp'
import { getWishlistProducts } from '../../libs/wcs/wishlist'
import { includePlacement } from '../../libs/wcs/cms'
import { parseValue, safeJSONParse } from '../../libs/utils/string'
import { updateUrl } from '../../libs/utils/url'
import qs from 'qs'
import isEmpty from 'lodash/isEmpty'
import { getCurrentCountry } from '../../libs/utils/currentCountry'

export const ACTION_LOAD_SUGGESTIONS = 'ACTION_LOAD_SUGGESTIONS'
export const ACTION_UPDATE_SEARCH_TERM = 'ACTION_UPDATE_SEARCH_TERM'
export const ACTION_LOAD_PLP_FACETS = 'ACTION_LOAD_PLP_FACETS'
export const ACTION_LOAD_SUGGESTED_PRODUCTS = 'ACTION_LOAD_SUGGESTED_PRODUCTS'
export const ACTION_LOAD_MORE_SUGGESTED_PRODUCTS = 'ACTION_LOAD_MORE_SUGGESTED_PRODUCTS'
export const ACTION_UPDATE_SEARCH_OVERLAY_OPEN = 'ACTION_UPDATE_SEARCH_OVERLAY_OPEN'
export const ACTION_CLEAR_SUGGESTED_PRODUCTS = 'ACTION_CLEAR_SUGGESTED_PRODUCTS'
export const ACTION_LOAD_MORE_SUGGESTED_CONTENT = 'ACTION_LOAD_MORE_SUGGESTED_CONTENT'
export const ACTION_LOAD_SUGGESTED_CONTENT = 'ACTION_LOAD_SUGGESTED_CONTENT'
export const ACTION_LOAD_CMS_PLACEMENTS = 'ACTION_LOAD_CMS_PLACEMENTS'
export const ACTION_LOAD_NO_RESULT_PRODUCTS = 'ACTION_LOAD_NO_RESULT_PRODUCTS'
export const ACTION_LOAD_TOP_BRANDS = 'ACTION_LOAD_TOP_BRANDS'
export const ACTION_TOGGLE_PLP_FILTER = 'ACTION_TOGGLE_PLP_FILTER'
export const ACTION_UPDATE_FACETS = 'ACTION_UPDATE_FACETS'
export const ACTION_TOGGLE_SIDEBAR = 'ACTION_TOGGLE_SIDEBAR'
export const ACTION_UPDATE_IDS_FILTERS_CHECKED = 'ACTION_UPDATE_IDS_FILTERS_CHECKED'
export const ACTION_TOGGLE_FILTER_ITEM = 'ACTION_TOGGLE_FILTER_ITEM'
export const ACTION_REMOVE_FACET_COUNT = 'ACTION_REMOVE_FACET_COUNT'
export const ACTION_UPDATE_FILTER_CATEGORY_LABEL = 'ACTION_UPDATE_FILTER_CATEGORY_LABEL'
export const ACTION_CLONE_FILTER_OPTIONS_LIST = 'ACTION_CLONE_FILTER_OPTIONS_LIST'
export const ACTION_RETRIEVE_FAVORITE_LIST_FROM_SEARCH = 'ACTION_RETRIEVE_FAVORITE_LIST_FROM_SEARCH'
export const ACTION_RETRIEVE_FAVORITE_LIST_FROM_SEARCH_NO_RESULT =
  'ACTION_RETRIEVE_FAVORITE_LIST_FROM_SEARCH_NO_RESULT'
export const ACTION_TOGGLE_FAVORITE_FROM_SEARCH = 'ACTION_TOGGLE_FAVORITE_FROM_SEARCH'
export const ACTION_TOGGLE_FAVORITE_FROM_SEARCH_NO_RESULT = 'ACTION_TOGGLE_FAVORITE_FROM_SEARCH_NO'

const useAlgolia = window.algoliaConfig?.isActive || false

export const actions = {
  // Loads search autosuggestion  products and words from server (fresh search)
  [ACTION_LOAD_SUGGESTIONS]: async function({ commit, dispatch, state }, { searchTerm, page }) {
    let data
    const currentParams = qs.parse(decodeURIComponent(window.location.search), {
      ignoreQueryPrefix: true,
    })
    const sessionFacets = safeJSONParse(
      sessionStorage.getItem('searchSessionFilters_' + getCurrentCountry())
    )
    let numPage = page
    //reset filters if search term is the same as the last one:
    if (state?.search?.lastSearchTerm && searchTerm !== state?.search?.lastSearchTerm) {
      dispatch(ACTION_UPDATE_IDS_FILTERS_CHECKED, { reset: true, resetSort: true })
      sessionFacets.orderBy = 'default'
      commit(UPDATE_FACETS, sessionFacets)
    } else if (!isEmpty(currentParams.q)) {
      commit(UPDATE_FACETS, sessionFacets)
      numPage = sessionFacets.currentPage || 1
    }
    try {
      data =
        searchTerm && searchTerm.length > 0
          ? await getSuggestions(searchTerm, numPage, state.facetsSelected, 0)
          : {
              products: [],
              suggestedWords: null,
              spellcheck: [],
              collationQuery: '',
              redirectUrl: '',
              contentResults: null,
            }
      commit(UPDATE_LOAD_SUGGESTIONS, { ...data })
      commit(UPDATE_RESET_PAGINATION_DATA, { ...data })
      updateUrl({ q: state.searchTerm })
      //replace search term with the actual keyword sent to api
      commit(UPDATE_LAST_SEARCH_TERM, state.searchTerm)
    } catch (e) {
      console.log('Algolia Search API server error ', e)
    }

    //update facets only at new search or first load
    if (
      (!state.facetsSelected.idsFiltersCheckedList.length > 0 &&
        state.facetsSelected.orderBy == 'default') ||
      !isEmpty(currentParams.q)
    ) {
      const extraFacets = []
      const { facet, facetParams, facetToSelect } = await getPlpFacetStart({
        extraFacets,
        searchTerm: state.searchTerm,
        idsFiltersCheckedList: state.facetsSelected.idsFiltersCheckedList,
      })

      // create facet for the panel on right, sort option list
      commit(UPDATE_LOAD_PLP_FACETS, facet)
      //
      //commit('module/mutation', payload, { root: true })
      // add the query parameter (if present) to initialize vue state
      facetParams.orderBy = sessionFacets.orderBy || 'default'
      commit(UPDATE_FACETS, facetParams)
      // add the check to filters that should be selected
      facetToSelect.forEach(f => {
        commit(UPDATE_IDS_FILTERS_CHECKED, f)
      })
      commit(UPDATE_FACETS_COUNT, { facet })
    }
  },
  // load just products (replace existing) from search in case suggested word is hovered or clicked
  [ACTION_LOAD_SUGGESTED_PRODUCTS]: async function({ commit, dispatch, state }, { searchTerm }) {
    //dispatch(ACTION_UPDATE_SEARCH_TERM, state.searchTerm)
    let data
    try {
      //reset filters on new search by suggested word
      dispatch(ACTION_UPDATE_IDS_FILTERS_CHECKED, { reset: true, resetSort: true })

      data = await getSuggestions(searchTerm, 1, state.facetsSelected)
      updateUrl({ q: searchTerm })
      commit(UPDATE_LOAD_SUGGESTED_PRODUCTS, { ...data })
      //replace search term with the actual keyword sent to api
      commit(UPDATE_LAST_SEARCH_TERM, state.searchTerm)

      const extraFacets = []
      const { facet, facetParams, facetToSelect } = await getPlpFacetStart({
        extraFacets,
        searchTerm: state.searchTerm,
      })
      // create facet for the panel on right, sort option list
      commit(UPDATE_LOAD_PLP_FACETS, facet)
      //
      //commit('module/mutation', payload, { root: true })
      // add the query parameter (if present) to initialize vue state
      commit(UPDATE_FACETS, facetParams)
      // add the check to filters that should be selected
      facetToSelect.forEach(f => {
        commit(UPDATE_IDS_FILTERS_CHECKED, f)
      })

      if (useAlgolia) {
        commit(UPDATE_REDIRECT_URL, data.redirectUrl)
      }
    } catch (e) {
      console.log('Algolia Search API server error ', e)
    }
  },
  // Loads more search autosuggestion products from server
  [ACTION_LOAD_MORE_SUGGESTED_PRODUCTS]: async function({ commit, state }, { searchTerm, page }) {
    let data
    try {
      data = await getSuggestions(searchTerm, page, state.facetsSelected)
      commit(UPDATE_LOAD_MORE_SUGGESTED_PRODUCTS, { ...data })
      commit(UPDATE_FACETS, state.facetsSelected)
    } catch (e) {
      console.log('Algolia Search API server error ', e)
    }
  },
  [ACTION_UPDATE_SEARCH_TERM]: function({ commit }, searchTerm) {
    commit(UPDATE_SEARCH_TERM, searchTerm)
  },
  [ACTION_UPDATE_SEARCH_OVERLAY_OPEN]: function({ commit }, overlayIsOpen) {
    commit(UPDATE_SEARCH_OVERLAY_OPEN, overlayIsOpen)
  },
  [ACTION_CLEAR_SUGGESTED_PRODUCTS]: function({ commit }) {
    commit(UPDATE_CLEAR_SUGGESTED_PRODUCTS)
  },
  // Load more search content items from server
  [ACTION_LOAD_MORE_SUGGESTED_CONTENT]: async function({ commit }, { searchTerm, page }) {
    let data
    try {
      data = await getSuggestedContent(searchTerm, page)
      commit(UPDATE_LOAD_MORE_SUGGESTED_CONTENT, data)
    } catch (e) {
      console.log('Algolia Search API server error ', e)
    }
  },
  // Load search content items from server
  [ACTION_LOAD_SUGGESTED_CONTENT]: async function({ commit }, { searchTerm, page }) {
    let data
    try {
      data = await getSuggestedContent(searchTerm, page)
      commit(UPDATE_LOAD_SUGGESTED_CONTENT, data)
    } catch (e) {
      console.log('Algolia Search API server error ', e)
    }
  },
  [ACTION_LOAD_CMS_PLACEMENTS]: async function({ commit }, param) {
    const name = `searchPlacements${window.algoliaConfig.isEarlyAccessParam ? 'EA' : ''}`
    let data =
      safeJSONParse(sessionStorage.getItem(`${name}_` + getCurrentCountry())) ||
      (await includePlacement(param, name))
    if (data) {
      let container = document.createElement('div')
      container.innerHTML = data.content
      const childrens = container.querySelectorAll('[placement-id]')
      let placements = []
      childrens.forEach(children => {
        const child = children.children[0]
        let name = child.localName
        let templates = {}
        let props = {}
        for (let template of child.children) {
          templates[template.attributes[0].localName.replace('#', '')] =
            template.content.children[0].outerHTML
        }
        for (let attribute of child.attributes) {
          props[attribute.localName.replace(':', '')] = parseValue(attribute.value)
        }
        placements.push({ name, templates, props })
      })
      commit(UPDATE_LOAD_CMS_PLACEMENTS, placements)
    }
  },
  [ACTION_LOAD_NO_RESULT_PRODUCTS]: async function({ commit }, config) {
    let products
    if (config == 'rule') {
      await algoliaGetRule('NO_RESULT', 6).then(result => (products = result.hits))
    } else {
      await algoliaGetTrendingItems().then(result => (products = result.hits))
    }
    products = products
      .map(product => {
        let item = getAlgoliaProductNormalized(product)
        item.inventoryQuantity = Number(product.inventoryQuantity) > 0
        return item
      })
      .filter(item => item.inventoryQuantity === true)
    commit(UPDATE_NO_RESULT, products.slice(0, 6))
  },
  [ACTION_LOAD_TOP_BRANDS]: async function({ commit }) {
    let data
    try {
      data = await algoliaGetTrendingFacets()
      let brands = getAlgoliaTopBrandsNormalized(data)
      commit(UPDATE_TOP_BRANDS, brands)
    } catch (e) {
      console.log('Algolia Search API server error ', e)
    }
  },
  [ACTION_TOGGLE_PLP_FILTER]: function({ /*commit,*/ dispatch, state }) {
    debug('%s payload %o', ACTION_TOGGLE_PLP_FILTER, !state.ui.plpLoadingFacets)
    // commit(UPDATE_UI, { plpLoadingFacets: !state.ui.plpLoadingFacets })
    dispatch(ACTION_TOGGLE_SIDEBAR)
  },
  [ACTION_UPDATE_FACETS]: function(
    { dispatch, commit, state },
    { override, updateUi, clearUrl, ...payload }
  ) {
    debug('%s payload %o', ACTION_UPDATE_FACETS, payload)
    commit(UPDATE_FACETS, payload)
    dispatch(ACTION_LOAD_SUGGESTIONS, { searchTerm: state.searchTerm, page: 0 })
    //dispatch(ACTION_LOAD_PLP_FACETS)
  },
  [ACTION_TOGGLE_SIDEBAR]: function({ commit, state }) {
    const sidebar = !state.ui.sidebar
    debug('%s action', ACTION_TOGGLE_SIDEBAR)
    commit(UPDATE_UI, { sidebar: sidebar })
    commit(UPDATE_UI_BODY, { overflow: sidebar ? 'hidden' : '' })
  },
  [ACTION_UPDATE_IDS_FILTERS_CHECKED]: function({ commit, dispatch }, payload) {
    debug('%s payload %o', ACTION_UPDATE_IDS_FILTERS_CHECKED, payload)
    commit(UPDATE_IDS_FILTERS_CHECKED, payload)
    dispatch(ACTION_LOAD_PLP_FACETS)
  },
  [ACTION_TOGGLE_FILTER_ITEM]: function({ commit }, label) {
    debug('%s payload %o', ACTION_TOGGLE_FILTER_ITEM, label)
    commit(UPDATE_UI, { filterItemClicked: label })
  },
  [ACTION_REMOVE_FACET_COUNT]: function({ commit }, id) {
    commit(UPDATE_REMOVE_FACET_COUNT, id)
  },
  [ACTION_UPDATE_FILTER_CATEGORY_LABEL]: function({ commit }, payload) {
    commit(UPDATE_FILTER_CATEGORY_LABEL, payload)
  },
  [ACTION_CLONE_FILTER_OPTIONS_LIST]: function({ commit }, payload) {
    commit(UPDATE_CLONE_FILTER_OPTIONS_LIST, payload)
  },
  [ACTION_LOAD_PLP_FACETS]: async function({ commit, state }) {
    // eslint-disable-next-line no-unused-vars
    const { currentPage, ...payload } = state.facetsSelected
    commit(UPDATE_UI, { plpLoadingFacets: true })
    debug('%s payload %o', ACTION_LOAD_PLP_FACETS, payload)
    //add extraFacets to payload so they can be evaluated while fetching facets
    payload.extraFacets = state.facetsSelected.extraFacets
    payload.searchTerm = state.searchTerm

    const data = await getPlpFacets(payload)
    commit(UPDATE_LOAD_PLP_FACETS, data)
    if (!state.isFacetsFirstLoad) {
      commit(UPDATE_IS_FACETS_FIRST_LOAD, true)
    }
    commit(UPDATE_UI, { plpLoadingFacets: false })
  },

  [ACTION_RETRIEVE_FAVORITE_LIST_FROM_SEARCH_NO_RESULT]: async function({ commit }, catentryId) {
    const wishlistItems = await getWishlistProducts()
    commit(UPDATE_RETRIEVE_FAVORITE_LIST_FROM_SEARCH_NO_RESULT, { wishlistItems, catentryId })
  },
  [ACTION_RETRIEVE_FAVORITE_LIST_FROM_SEARCH]: async function({ commit }, catentryId) {
    const wishlistItems = await getWishlistProducts()
    commit(UPDATE_RETRIEVE_FAVORITE_LIST_FROM_SEARCH, { wishlistItems, catentryId })
  },

  [ACTION_TOGGLE_FAVORITE_FROM_SEARCH]: function({ commit }, params) {
    commit(UPDATE_TOGGLE_FAVORITE_FROM_SEARCH, params)
  },

  [ACTION_TOGGLE_FAVORITE_FROM_SEARCH_NO_RESULT]: function({ commit }, params) {
    commit(UPDATE_TOGGLE_FAVORITE_FROM_SEARCH_NO_RESULT, params)
  },
}
