import Vue from 'vue'

/* All components to be declared */
/* Declare in this way components that will be put in chunks and loaded not immediately */
Vue.component('sgh-back-to-top', () =>
  import(
    /* webpackChunkName: "utilities" */
    './components/back-to-top/back-to-top.vue'
  )
)

Vue.component('sgh-banner-pwa', () =>
  import(
    /* webpackChunkName: "banner-pwa" */
    './components/banner/sgh-banner-pwa.vue'
  )
)

Vue.component('sgh-country-redirect', () =>
  import(
    /* webpackChunkName: "country" */
    './components/country-redirect/country-redirect.vue'
  )
)

Vue.component('sgh-country-redirect-generic-modal', () =>
  import(
    /* webpackChunkName: "country" */
    './components/country-redirect/country-redirect-generic.vue'
  )
)

Vue.component('sgh-country-selector-list', () =>
  import(
    /* webpackChunkName: "country" */
    './components/country-selector/country-selector-list.vue'
  )
)

Vue.component('sgh-country-selector', () =>
  import(
    /* webpackChunkName: "country" */
    './components/country-selector/country-selector.vue'
  )
)

Vue.component('sgh-disclaimer', () =>
  import(
    /* webpackChunkName: "utilities" */
    './components/disclaimer/disclaimer.vue'
  )
)

Vue.component('sgh-early-access-container', () =>
  import(
    /* webpackChunkName: "early-access" */
    './components/early-access/early-access-container.vue'
  )
)

Vue.component('sgh-early-access-form', () =>
  import(
    /* webpackChunkName: "early-access" */
    './components/early-access/early-access-form.vue'
  )
)

Vue.component('sgh-frame-genius', () =>
  import(
    /* webpackChunkName: "frame-genius" */
    './components/frame-genius/frame-genius.vue'
  )
)

Vue.component('sgh-generic-loader', () =>
  import(
    /* webpackChunkName: "utilities" */
    './components/generic-loader/generic-loader.vue'
  )
)

Vue.component('sgh-load-more', () =>
  import(
    /* webpackChunkName: "utilities" */
    './components/load-more/load-more.vue'
  )
)

Vue.component('sgh-loading', () =>
  import(
    /* webpackChunkName: "utilities" */
    './components/loading/loading.vue'
  )
)

Vue.component('sgh-mention-me', () =>
  import(
    /* webpackChunkName: "mention-me" */
    './components/mention-me/sgh-mention-me.vue'
  )
)

Vue.component('sgh-login', () =>
  import(
    /* webpackChunkName: "menu-account" */
    './components/my-account/components/login/login.vue'
  )
)

Vue.component('sgh-join', () =>
  import(
    /* webpackChunkName: "menu-account" */
    './components/my-account/components/join/join.vue'
  )
)

Vue.component('sgh-password-check', () =>
  import(
    /* webpackChunkName: "menu-account" */
    './components/my-account/components/password-check.vue'
  )
)

Vue.component('sgh-menu-link-mobile-wishlist', () =>
  import(
    /* webpackChunkName: "menu-account" */
    './components/my-account/components/menu/menu-link-mobile-wishlist.vue'
  )
)

Vue.component('sgh-join-us-black-box', () =>
  import(
    /* webpackChunkName: "menu-account" */
    './components/my-account/components/overlay/join-us/join-us-black-box.vue'
  )
)

Vue.component('sgh-my-account-menu-overlay', () =>
  import(
    /* webpackChunkName: "menu-account" */
    './components/my-account/components/overlay/my-account-menu-overlay.vue'
  )
)

Vue.component('sgh-myaccount-overlay', () =>
  import(
    /* webpackChunkName: "menu-account" */
    './components/my-account/components/overlay/my-account-overlay.vue'
  )
)

Vue.component('sgh-login-registration-wrap', () =>
  import(
    /* webpackChunkName: "menu-account" */
    './components/my-account/components/login-registration-wrap.vue'
  )
)

Vue.component('my-account-error', () =>
  import(
    /* webpackChunkName: "menu-account" */
    './components/my-account/components/my-account-error.vue'
  )
)

Vue.component('sgh-new-popup', () =>
  import(
    /* webpackChunkName: "cms-modules" */
    './components/new-popup/new-popup.vue'
  )
)

Vue.component('sgh-cms-strip-banner', () =>
  import(
    /* webpackChunkName: "cms-modules" */
    './components/cms-modules/strip-banner-module/strip-banner.vue'
  )
)

Vue.component('sgh-loyalty-notification-banner', () =>
  import(
    /* webpackChunkName: "cms-modules" */
    './components/loyalty/loyalty-notification.vue'
  )
)

Vue.component('sgh-notification', () =>
  import(
    /* webpackChunkName: "cms-modules" */
    './components/ui-components/notification.vue'
  )
)

Vue.component('sgh-minicart-suggestion-promo', () =>
  import(
    /* webpackChunkName: "minicart" */
    './components/pdp-minicart/pdp-minicart-suggestion-promo.vue'
  )
)

Vue.component('sgh-minicart-suggestion', () =>
  import(
    /* webpackChunkName: "minicart" */
    './components/pdp-minicart/pdp-minicart-suggestion.vue'
  )
)

Vue.component('sgh-pdp-minicart', () =>
  import(
    /* webpackChunkName: "minicart" */
    './components/pdp-minicart/pdp-minicart.vue'
  )
)

Vue.component('sgh-search-input', () =>
  import(
    /* webpackChunkName: "search-panel" */
    './components/search-panel/search-input.vue'
  )
)

Vue.component('sgh-search-suggested-word', () =>
  import(
    /* webpackChunkName: "search-panel" */
    './components/search-panel/search-suggested-word.vue'
  )
)

Vue.component('sgh-popup', () =>
  import(
    /* webpackChunkName: "utilities" */
    './components/sgh-popup/sgh-popup.vue'
  )
)

Vue.component('sgh-slide', () =>
  import(
    /* webpackChunkName: "slider" */
    './components/sgh-slider/sgh-slide.vue'
  )
)

Vue.component('sgh-slider', () =>
  import(
    /* webpackChunkName: "slider" */
    './components/sgh-slider/sgh-slider.vue'
  )
)

Vue.component('sgh-generic-sidebar', () =>
  import(
    /* webpackChunkName: "sidebar" */
    './components/sidebar/generic-sidebar.vue'
  )
)

Vue.component('sgh-plp-badge', () =>
  import(
    /* webpackChunkName: "tile" */
    './components/tile/plp-badge.vue'
  )
)

Vue.component('sgh-plp-price', () =>
  import(
    /* webpackChunkName: "tile" */
    './components/tile/plp-tile-price.vue'
  )
)

Vue.component('sgh-plp-tile', () =>
  import(
    /* webpackChunkName: "tile" */
    './components/tile/plp-tile.vue'
  )
)

Vue.component('sgh-scroll-tiles', () =>
  import(
    /* webpackChunkName: "tile" */
    './components/tile/scroll-tiles.vue'
  )
)

Vue.component('sgh-copy-clipboard', () =>
  import(
    /* webpackChunkName: "header-top" */
    './components/copy-clipboard/copy-clipboard.vue'
  )
)

Vue.component('sgh-news-letter', () =>
  import(
    /* webpackChunkName: "header-top" */
    './components/ui-components/news-letter.vue'
  )
)

Vue.component('sgh-popup-newsletter', () =>
  import(
    /* webpackChunkName: "header-top" */
    './components/ui-components/sgh-popup-newsletter.vue'
  )
)

Vue.component('sgh-header-top-controller', () =>
  import(
    /* webpackChunkName: "header-top" */
    './components/header-top/header-top-controller.vue'
  )
)

Vue.component('sgh-vm-container', () =>
  import(
    /* webpackChunkName: "virtual-mirror" */
    './components/virtual-mirror/virtual-mirror-container.vue'
  )
)

Vue.component('sgh-search-overlay', () =>
  import(
    /* webpackChunkName: "search-overlay" */
    './components/search-panel/sgh-search-overlay.vue'
  )
)

/* Declare in this way component that needs to appear immediately (will be put in main.js) */
import SghHeaderTop from './components/header-top/header-top.vue'
Vue.component('sgh-header-top', SghHeaderTop)

import SghHeaderTopWrapper from './components/header-top/header-top-wrapper.vue'
Vue.component('headerTopWrapper', SghHeaderTopWrapper)

import SghHeaderNavigation from './components/header-navigation/navigation.vue'
Vue.component('sgh-header-navigation', SghHeaderNavigation)

import SghMenuItemMobile from './components/header-navigation/menu-item-mobile.vue'
Vue.component('menu-item-mobile', SghMenuItemMobile)

import hamburgerIcon from './components/header-navigation/hamburger-icon.vue'
Vue.component('hamburger-icon', hamburgerIcon)

import SghMenuWrapper from './components/my-account/components/menu/menu-wrapper.vue'
Vue.component('sgh-menu-wrapper', SghMenuWrapper)

import SghMenuWrapperMobile from './components/my-account/components/menu/menu-wrapper-mobile.vue'
Vue.component('sgh-menu-wrapper-mobile', SghMenuWrapperMobile)

import SghMenuLogged from './components/my-account/components/menu/menu-logged.vue'
Vue.component('sgh-menu-logged', SghMenuLogged)

import sghSwiperSlide from './components/swiper/swiper-slide.vue'
Vue.component('swiper-slide', sghSwiperSlide)

import sghSwiper from './components/swiper/swiper.vue'
Vue.component('sgh-swiper', sghSwiper)

import SghButton from './components/ui-components/button.vue'
Vue.component('sgh-button', SghButton)

import sghMonetate from './components/monetate/monetate.vue'
Vue.component('sgh-monetate', sghMonetate)

import sghPayOverTime from './components/pay-over-time/sgh-pay-over-time.vue'
Vue.component('sgh-pay-over-time', sghPayOverTime)

import sghPayOverTimePopup from './components/pay-over-time/sgh-pay-over-time-popup.vue'
Vue.component('sgh-pay-over-time-popup', sghPayOverTimePopup)

/* CMS MODULES THAT MUST BE VISIBLE IN ALL PAGES */
import sghTrendsAnchorButtons from './components/cms-modules/trends-anchor-buttons/trends-anchor-buttons.vue'
Vue.component('sgh-cms-trends-anchor-buttons', sghTrendsAnchorButtons)

import sghTrendsAnchorButtonsAnchorBackground from './components/cms-modules/trends-anchor-buttons/components/trends-anchor-buttons-anchor-background.vue'
Vue.component('sgh-cms-trends-anchor-buttons-anchor-background', sghTrendsAnchorButtonsAnchorBackground)

import sghTrendsAnchorButtonsAnchorImage from './components/cms-modules/trends-anchor-buttons/components/trends-anchor-buttons-anchor-image.vue'
Vue.component('sgh-cms-trends-anchor-buttons-anchor-image', sghTrendsAnchorButtonsAnchorImage)

import sghTrendsAnchorButtonsAnchorText from './components/cms-modules/trends-anchor-buttons/components/trends-anchor-buttons-anchor-text.vue'
Vue.component('sgh-cms-trends-anchor-buttons-anchor-text', sghTrendsAnchorButtonsAnchorText)

import sghTrendsAnchorButtonsChip from './components/cms-modules/trends-anchor-buttons/components/trends-anchor-buttons-chip.vue'
Vue.component('sgh-cms-trends-anchor-buttons-chip', sghTrendsAnchorButtonsChip)

import sghTrendsAnchorButtonsContent from './components/cms-modules/trends-anchor-buttons/components/trends-anchor-buttons-content.vue'
Vue.component('sgh-cms-trends-anchor-buttons-content', sghTrendsAnchorButtonsContent)