import { render, staticRenderFns } from "./menu-item-mobile.vue?vue&type=template&id=8980a074&scoped=true"
import script from "./menu-item-mobile.vue?vue&type=script&lang=js"
export * from "./menu-item-mobile.vue?vue&type=script&lang=js"
import style0 from "./menu-item-mobile.vue?vue&type=style&index=0&id=8980a074&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8980a074",
  null
  
)

export default component.exports