
  import AnchorLayout from '../components/trends-anchor-buttons-anchor-layout.vue'
  export default {
     name: 'sgh-cms-trends-anchor-buttons-anchor-image',

    components: {
      AnchorLayout
    },

    props: {
      imgAlt: {
        type: String,
        default: ''
      },
      imgSrc: {
        type: String,
        default: '#'
      },
      text: {
        type: String,
        default: ''
      },
      textAlignment: {
        type: String,
        default: ''
      }
    },

    computed: {
      returnTextAlignment() {
        switch (this.textAlignment) {
          case 'center': {
            return 'content-center'
          }
          case 'top': {
            return 'content-start'
          }
          default: {
            return 'content-end'
          }
        }
      }
    }
  }
