
import AnchorLayout from '../components/trends-anchor-buttons-anchor-layout.vue'

export default {
  name: 'SghCmsTrendsAnchorButtonsContent',

  components: {
    AnchorLayout,
  },

  props: {
    type: {
      type: String,
      default: 'text',
      validator: (value) => ['text', 'image', 'chip', 'text-background', 'text-icon'].includes(value),
    },
    text: {
      type: String,
      default: '',
    },

    textStyle: {
      type: String,
      default: '',
    },
    textAlignment: {
      type: String,
      default: '',
    },
    imgSrc: {
      type: String,
      default: '#',
    },
    alt: {
      type: String,
      default: '',
    },
    iconClass: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isScrolled: false,
      backgroundColorClass: '',
    }
  },

  computed: {
    isMobile() {
      return this.$mq === 'tablet' || this.$mq === 'tabletLandscape'
    },
    contentTextClass() {
      switch (this.currentType) {
        case 'text': return 'sgh-cms__trends-anchor-buttons--anchor-text ' + this.textStyle
        case 'chip': return 'sgh-cms__trends-anchor-buttons--chip ' + this.textStyle
        case 'text-background': return 'sgh-cms__trends-anchor-buttons--text-background ' + this.textStyle
        case 'image': return 'sgh-cms__trends-anchor-buttons--figure ' + this.textStyle
        case 'text-icon': return 'sgh-cms__trends-anchor-buttons--text-icon ' + this.textStyle
        default: return ''
      }
    },
    textAlignmentClass() {
      switch (this.textAlignment) {
        case 'center': return 'content-center'
        case 'top': return 'content-start'
        default: return 'content-end'
      }
    },
    currentType() {
      if (this.isScrolled && (this.type === 'image' || this.type === 'text-background')) {
        return 'chip'
      }
      return this.type
    },
    backgroundImageDynamicStyle() {
      return {
        backgroundImage: `url(${this.imgSrc})`,
        opacity: this.isScrolled ? 0 : 1,
        transition: 'opacity 0.5s ease-in-out',
      }
    },
    pClass() {
      return {
        'sgh-cms__trends-anchor-buttons--figcaption bottom-0 pb-4 px-4': this.currentType === 'image',
        [`${this.textAlignmentClass} h-full pb-4 px-4`]: !this.isScrolled,
      }
    },
    containerBackgroundStyle() {
      return {
        backgroundColor: this.backgroundColor,
      }
    },
    baseLayoutClass() {
      const isImageOrTextBackground = this.type === 'image' || this.type === 'text-background'
      const isScrolledImageOrTextBackground = this.isScrolled && isImageOrTextBackground
      return isImageOrTextBackground || isScrolledImageOrTextBackground
    },
    
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    handleScroll() {
      const currentScrollPosition = window.scrollY

      if (currentScrollPosition > 0) {
        this.isScrolled = true

        const regex = /cms-custom-color-[\w-]+/
        const match = this.textStyle.match(regex)

        if ((this.type === 'image' || this.type === 'text-background')) {
          if (match) {
            this.backgroundColorClass = match[0]
          } else {
            if (this.textStyle.includes('cms-custom-text-color-white')) {
              this.backgroundColorClass = 'cms-custom-color-black'
            } else if (this.textStyle.includes('cms-custom-text-color-black')) {
              this.backgroundColorClass = 'cms-custom-color-white'
            }
          }
        }
      } else {
        this.isScrolled = false
        this.backgroundColorClass = ''
      }
    },
  },
}
