
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { getCurrentCountry } from '../../../../libs/utils/currentCountry'

import { ACTION_LOGOUT_MYACCOUNT } from '../../../../store/actions/my-account'
import { UPDATE_COUNT_WISHLIST_ITEM } from '../../../../store/mutations/wishlist'

const { mapActions, mapGetters: mapGettersMyAccount } = createNamespacedHelpers('myAccount')
const {
  mapMutations: mapMutationsWishlist,
  mapGetters: mapGettersWishlist,
} = createNamespacedHelpers('wishlist')

export default {
  name: 'sgh-menu-logged',

  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['storeConfig']),
    ...mapGettersMyAccount(['myAccountDetails', 'profileDetails']),
    ...mapGettersWishlist(['counterWishlist']),

    getWishlistUrl() {
      return `/${getCurrentCountry()}/wishlist`
    },

    quantityWishlist() {
      return this.counterWishlist
    },

    countryWithAppointments() {
      return sessionStorage.getItem('isTabActive') === 'true'
    },
    isLoyaltyProgramEnabled() {
      return sessionStorage.getItem('isLoyaltyProgramEnabled') === 'true'
    },
    filteredMenuPages() {
      let filteredPages = this.countryWithAppointments
        ? this.myAccountDetails.pages
        : this.myAccountDetails.pages.filter(page => page !== 'get-an-appointment')
      return (filteredPages = this.isLoyaltyProgramEnabled
        ? filteredPages
        : filteredPages.filter(page => page !== 'your-loyalty-program'))
    },
    isNotLoyaltyMember() {
      return this.profileDetails?.objectPerson?.isLoyaltyActive === false
    },
  },

  methods: {
    ...mapActions({
      logoutUserAction: ACTION_LOGOUT_MYACCOUNT,
    }),
    ...mapMutationsWishlist({
      updateCounterWishlist: UPDATE_COUNT_WISHLIST_ITEM,
    }),

    setLocalStorageGuestUserLoginData() {
      sessionStorage.setItem('User_LoginStatus', 'Guest')
      sessionStorage.removeItem('User_Email_MD5')
    },
    logoutUser() {
      this.logoutUserAction(false).then(() => {
        if (this.myAccountDetails.loggedIn) return false
        if (window.localStorage.getItem(`SGH_WISHLISTITEMS_${getCurrentCountry()}`)) {
          window.localStorage.removeItem(`SGH_WISHLISTITEMS_${getCurrentCountry()}`)
          this.updateCounterWishlist(0)
        }
        this.setLocalStorageGuestUserLoginData()
        const objCrypto = {
          User_Email_MD5: '',
          User_Email_SHA256: '',
        }

        window.tealium_data2track.push(objCrypto)

        if (this.myAccountDetails.pages.includes(this?.$route?.name))
          window.location = `${window.location.origin}/${getCurrentCountry()}`
        window.location.reload()
      })
    },

    getUrl(page) {
      return `/${getCurrentCountry()}/myaccount/${page}`
    },

    isDisabled(element) {
      if (
        this.profileDetails?.objectPerson?.personalInfo?.registrationApprovalStatus === 'Approved'
      ) {
        return element === 'details' ? false : true
      } else {
        return false
      }
    },
  },
}
