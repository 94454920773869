
export default {
  name: 'hamburger-menu',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
}
