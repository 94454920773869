import {
  UiStateType,
} from '../types/ui.types'
import { ACTION_UPDATE_MINICART_STATUS } from '../actions/ui'


export const UPDATE_TOGGLE_POPUP = 'UPDATE_TOGGLE_POPUP'
export const UPDATE_SHOW_LOADER = 'UPDATE_SHOW_LOADER'
export const UPDATE_TOGGLE_POPUP_ID = 'UPDATE_TOGGLE_POPUP_ID'
export const UPDATE_TOGGLE_PANEL_ID = 'UPDATE_TOGGLE_PANEL_ID'
export const UPDATE_POPUP_PROP = 'UPDATE_POPUP_PROP'
export const UPDATE_TOAST_STATUS = 'UPDATE_TOAST_STATUS'
export const UPDATE_MINICART_STATUS = 'UPDATE_MINICART_STATUS'
export const mutations = {
  [UPDATE_SHOW_LOADER]: function(state: UiStateType, payload: boolean) {
    state.showLoader = payload
  },
  [UPDATE_TOGGLE_POPUP]: function(state: UiStateType, payload: boolean) {
    state.isModalOpen = payload
  },
  [UPDATE_TOGGLE_POPUP_ID]: function(state: UiStateType, payload: {
    popupId: string,
    open?: boolean
  }) {
    try {
      const { popupId, open } = payload
      const actualPopupState = state.popups[popupId]
      const realopen = open !== undefined ? open : !actualPopupState?.open || false
      state.popups = !!actualPopupState ? {
        ...state.popups, ...{
          [popupId]: {
            ...actualPopupState,
            open: realopen,
          },
        },
      } : state.popups
    } catch (e: any) {
      throw new Error('Error updating popups state', e)
    }

  },
  [UPDATE_POPUP_PROP]: function(state: UiStateType, payload: {
    popupId: string,
    props?: Record<string, string | boolean>
  }) {
    try {
      const { popupId, props } = payload
      state.popups = !!state.popups[popupId] ? {
        ...state.popups, ...{
          [popupId]: {
            ...state.popups[popupId] || {},
            props: props || {},
          },
        },
      } : state.popups
    } catch (e: any) {
      throw new Error('Error updating popups state props', e)
    }

  },
  [UPDATE_TOGGLE_PANEL_ID]: function(state: UiStateType, payload: {
    panelId: string
    open?: boolean
  }) {
    try {
      const { panelId, open } = payload
      state.panels = !!state.panels[panelId] ? {
        ...state.panels, ...{
          [panelId]: {

            ...state.panels[panelId] || {},
            open: open || !state.panels[panelId].open || false,
          },
        },
      } : state.panels
    } catch (e: any) {
      throw new Error('Error updating panels state', e)
    }

  },
  [UPDATE_TOAST_STATUS]: function(state: UiStateType, payload: {
    toastId: string
    open?: boolean
  }) {
    try {
      const { toastId, open } = payload
      state.toasts = !!state.toasts[toastId] ? {
        ...state.toasts, ...{
          [toastId]: {
            ...state.toasts[toastId] || {},
            open: open || !state.toasts[toastId].open || false,
          },
        },
      } : state.toasts
    } catch (e: any) {
      throw new Error('Error updating toasts state', e)
    }

  },
  [UPDATE_MINICART_STATUS]: function(state: UiStateType, payload?: {
    open?: boolean
  }) {
    try {
      const { open } = payload || {}
      state.miniCart = {
        open: open || !state.miniCart.open || false,
      }
    } catch (e: any) {
      throw new Error('Error updating minicart state', e)
    }
  },
}
