
/*
TODO: Check with CMS team if is possible to align nav according to user choice
DEV NOTE: In case is possible set width: 100% and justify-items according to user choice to --nav
*/
export default {
  name: 'sgh-cms-trends-anchor-buttons-anchors-container',

  computed: {
    isMobile() {
      return this.$mq == 'tablet' || this.$mq == 'tabletLandscape'
    },
    isNavAvailable() {
      return !!this.$slots.navItem
    },
    bindSlotsLoading() {
      return this.$slots
    },
  },
  methods: {
    anchorsAlignment() {
      this.$nextTick(() => {
        if(document.querySelector('.sgh-cms__trends-anchor-buttons--module-name') && (document.querySelector('.sgh-cms__trends-anchor-buttons--anchor-text') || document.querySelector('.sgh-cms__trends-anchor-buttons--text-icon')))
          return this.$refs.anchorsNav.classList.add('sgh-cms__trends-anchor-buttons--align-right')
      })
    },
  },
  mounted() {
    this.anchorsAlignment()
  }
}
