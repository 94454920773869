
import { setCountdown } from '../../libs/utils/setCountdown'
export default {
  name: 'headerTopWrapper',
  props: {
    currentIndex: {
      type: Number,
      default: 0,
    },
    isController: {
      type: Boolean,
      default: true,
    },
    textColor: {
      type: String,
      default: '',
    },
    endsInTranslation: {
      type: String,
      default: '',
    },
  },
  methods: {
    pushTealium(index) {
      const slotsElm = this.$slots.default?.filter(c => !c.text)
      const camelCase = slotsElm[index].elm.innerText
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
      window.tealium_data2track.push({
        id: 'Click',
        data_element_id: 'X_X_GlobalBanner_Open',
        data_description: camelCase,
      })
    },
  },
  mounted() {
    this.$root.$on('activeController', index => {
      this.pushTealium(index)
    })
  },

  render(h) {
    const content = this.$slots.default?.filter(c => !c.text)
    let now = new Date()

    let url = window.location.href
    let queryString = url.split('?')[1]
    let params = new URLSearchParams(queryString)
    let previewDateParam = params.get('previewDate')

    if (previewDateParam) {
      let dateParts = previewDateParam.split(/[\s:-]+/)
      let year = dateParts[2]
      let month = dateParts[1] - 1
      let day = dateParts[0]
      let hours = dateParts[3]
      let minutes = dateParts[4]
      let formattedDate = new Date(year, month, day, hours, minutes)
      if (formattedDate) {
        now = new Date(formattedDate)
      }
    }

    now = Date.parse(now).toString()

    const wrapped = content?.map((slot, index) => {
      const dataCountdown = slot?.data?.attrs['data-countdown']

      const startDate = slot?.data?.attrs['data-start']

      const dataEnd = slot?.data?.attrs['data-end']
      const dataHighlighted = slot?.data?.attrs['data-highlighted'] == 'true' ? '__highlighted' : ''
      slot.data.attrs.tabindex = 0

      if (this.currentIndex === index) {
        if (slot.data.attrs.href && this.isController) slot.data.attrs.href = 'javascript:void(0)'
        if (dataCountdown === 'true' && startDate <= now && !(now > dataEnd)) {
          const VNodePipe = h('span', `| `)
          const VNode1 = h(
            'span',
            { class: `countdown${dataHighlighted}` },
            `${this.endsInTranslation}: `
          )
          const VNode2 = h(
            'span',
            { class: `countdown${dataHighlighted} slide-${index}` },
            `${setCountdown(dataEnd, 'days', now)}`
          )
          slot.children.push(VNodePipe)
          slot.children.push(VNode1)
          slot.children.push(VNode2)
          setInterval(
            function() {
              this.children[3].elm.innerHTML = `${setCountdown(dataEnd, 'days', now)}`
            }.bind(slot),
            1000
          )
        }
        return h('div', { key: index }, [slot])
      }
    })

    return h('div', { class: `sgh-header-top__text-wrapper ${this.textColor}` }, wrapped)
  },
}
