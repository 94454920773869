
import Swiper from 'swiper'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'

export default {
  name: 'sgh-swiper',
  props: {
    config: Object,
    wrapperClass: String,
    navigation: Boolean, // mutual exclusion with dragIcon
    pagination: Boolean,
    paginationOutside: Boolean,
    thumbs: Object,
    dragIcon: Boolean, // mutual exclusion with navigation
    scrollBar: Boolean,
    preventClicks:Boolean,
    preventClicksPropagation:Boolean,
    mobileonly: {
      type: Boolean,
      default() {
        return false
      },
    },
    isDesktopOnly: {
      type: Boolean,
      default() {
        return false
      },
    },
    paginationtype: {
      type: String,
      default() {
        return 'bullets'
      },
    },
    hidePaginationOnMobile: {
      type: Boolean,
      default: true,
    },
    lazyLoad: false,
  },
  data: () => ({
    swiper: null,
    slidesDomElements: null,
    initialNode: null,
    windowWidth: window.outerWidth,
    breakpointMobile: 1023,
  }),
  computed: {
    slides() {
      // if (this.swiper) {
      //   const { loop } = this.swiper.params
      //   console.log(this.swiper)
      //   return this.swiper.slides.length - (loop ? 2 : 0)
      // }
      return this.slidesDomElements
    },
    showPagination() {
      return this.pagination
    },
    showOutsidePagination() {
      return this.paginationOutside
    },
    showNavigation() {
      return this.navigation && (this.slidesDomElements === null || this.slidesDomElements > 1)
    },
    showScrollBar() {
      return this.scrollBar
    },
    isEnabledForDesktopOnly() {
      return this.isDesktopOnly ? this.windowWidth > this.breakpointMobile : true
    },
  },
  watch: {
    thumbs() {
      if (this.thumbs) {
        this._setup()
      }
    },
    slides(newValue) {
      this.$emit('slides', newValue)
    },
  },
  mounted() {
    if (this.thumbs === undefined) {
      if ((this.mobileonly && this.windowWidth <= this.breakpointMobile) || !this.mobileonly) {
        this._setup()
      }
    }

    window.addEventListener('resize', this.onResize)
    //hide dashbutton skeleton
    const scrollCategories = this.$el.classList.contains('sgh-scroll-categories')
    const dashButtonSkeleton = document.querySelector('.skeleton .dashbutton')
    if (scrollCategories && dashButtonSkeleton) {
      dashButtonSkeleton.classList.add('hidden')
    }
  },
  updated() {
    this.$nextTick(function () {
      if (this.mobileonly) {
        if (this.swiper && !this.swiper.destroyed && this.windowWidth > this.breakpointMobile) {
          this.swiper.destroy()
          this._desktopNodeUpdate()
          this.$forceUpdate()
        } else if (
          (!this.swiper || this.swiper.destroyed) &&
          this.windowWidth <= this.breakpointMobile
        ) {
          this._mobileNodeUpdate()
          this._setup()
        }
      }
    })
  },
  beforeMount() {
    // If swiper is only for mobile, on desktop not print div swiper-slide
    this.initialNode = this.$slots.default
    if (this.mobileonly && this.windowWidth > this.breakpointMobile) {
      this._desktopNodeUpdate()
    }
  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy()
    }
  },
  methods: {
    _setup() {
      this.slidesDomElements = this.$el.querySelectorAll('.swiper-slide').length

      if (this.$el.classList.contains('sgh-scroll-categories') && this.slidesDomElements <= 2) {
        this.config.loop = false
        this.config.allowTouchMove = false
        if (this.$mq !== 'desktop') {
          this.config.centerInsufficientSlides = true
        }
      }

      /* If there is a selected category, set it as the active slide in swiper */
      this.categorySelected = document.querySelector('.sgh-category--selected')
      if (this.categorySelected) {
        this.swiperElm = this.categorySelected.parentElement
        this.swiperWrapper = this.swiperElm.parentNode
        this.activeSlideIndex = Array.prototype.indexOf.call(
          this.swiperWrapper.children,
          this.swiperElm
        )
        this.config.initialSlide = this.activeSlideIndex
      }

      let configSwiper = {
        delay: 5000,
        navigation: {
          nextEl: this.$refs.nextNavigation,
          prevEl: this.$refs.prevNavigation,
        },
        thumbs: {
          swiper: this.thumbs,
        },
        grabCursor: true,
        pagination: {
          el: '.swiper-pagination',
          type: this.paginationtype,
        },
        mousewheel: {
          releaseOnEdges: true,
          forceToAxis: true,
        },
        lazy: this.lazyLoad,
        preloadImages: this.lazyLoad,
        watchSlidesVisibility: this.lazyLoad,
        
      }

      var swiper = new Swiper(this.$refs.swiper, Object.assign(configSwiper, this.config))

      this.$nextTick(function () {
        this.swiper = swiper
        this.swiper.on('resize', () => {
          this.$emit('resize')
        })

        this.$emit('init', this.swiper)

        this.swiper.on('slideNextTransitionEnd', () => {
          this.$emit('next', this.getSlideIndex())
        })
        this.swiper.on('slidePrevTransitionEnd', () => {
          this.$emit('prev', this.getSlideIndex())
        })
        this.swiper.on('slideNextTransitionStart', () => {
          if (this.swiper.previousIndex > this.swiper.realIndex) {
            this.$emit('nextAfterLastSlide')
          }
          this.$emit('startNext', this.getSlideIndex())
        })

        this.swiper.on('slidePrevTransitionStart', () => {
          if (this.swiper.previousIndex < this.swiper.realIndex) {
            this.$emit('prevBeforeFirstSlide')
          }
          this.$emit('startPrev', this.getSlideIndex())
        })

        this.swiper.on('click', e => {
          this.$root.$emit('clickSlide', e)
        })

        this.swiper.on('touchStart', () => {
          this.$root.$emit('moveSliderStart')
        })

        this.swiper.on('transitionEnd', () => {
          this.$root.$emit('moveSliderEnd')
          this.$emit('slideSwiped',this.getSlideIndex())
        })
        this.swiper.on('transitionStart', () => {
          this.$emit('moveSliderTransitionStart',this.getSlideIndex())
        })
      })
    },
    _desktopNodeUpdate() {
      this.$slots.default = this.$slots.default.map(node => {
        if (node.tag) node = node.componentOptions.children
        return node
      })
    },
    _mobileNodeUpdate() {
      this.$slots.default = this.initialNode
    },
    isCurrentIndex(index) {
      if (this.swiper) {
        return index === this.getSlideIndex()
      }
      return false
    },
    setCurrentIndex(index) {
      this.swiper.slideTo(index + 1)
    },
    getSlideIndex() {
      return this.swiper.realIndex
    },
    onClick() {
      this.$emit('click')
    },
    onNext() {
      this.swiper.slideNext()
    },
    onPrev() {
      this.swiper.slidePrev()
    },
    onResize() {
      this.windowWidth = window.outerWidth
    },
  },
}
